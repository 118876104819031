
import './landing.scss';
import { collection, getDocs, getFirestore, limit, orderBy, query } from 'firebase/firestore';
import LOGO_GAME_MOCK from '../../assets/images/logo-game-mock.png';
import GAME_ROOM_HEADER from '../../assets/images/game-room-header.png';
import NEXT_IMG from '../../assets/images/next-img.png';
import HeaderComponent from '../header/header';
import FooterComponent from '../footer/footer';
import { Carousel, CarouselItem } from 'react-bootstrap';
import CHALLENGING_JSON from '../../assets/json/challening.json';
import { initializeApp } from 'firebase/app';
import config from '../../config';
import { useEffect, useState } from 'react';
import LeaderBoardModel from '../model/leaderboard';
import GameTypeModel from '../model/game-type';
import Lottie from "lottie-react";
import LOADING_FILE from "../../assets/json/loading.json";
import UtilityService from '../utility/utility';
import IMG_TETRIS from '../../assets/images/tetris.png';
import IMG_2048 from '../../assets/images/2048.png';
import IMG_FLAPPY from '../../assets/images/flappy.png';
const app = initializeApp(config.firebaseConfig);
export const db = getFirestore(app);
const LandingComponent = () => {
    const [leaderScore, setLeaderScore] = useState<LeaderBoardModel[]>([]);
    const [gameType, setGameType] = useState<GameTypeModel[]>([]);
    const [loadGame, setLoadGame] = useState(true);

    const callGame = async () => {


        const gameRef = collection(db, "game-room");
        const q = query(gameRef);
        const leaderSc: any = [];
        const querySnapshot = await getDocs(q);
        let leaderSC: GameTypeModel[] = [];
        querySnapshot.forEach((doc) => {
            leaderSc.push(doc.data());
            const objectSC: GameTypeModel = {
                name: doc.data().name,
                id: doc.data().id,
                desc: doc.data().desc,
                url: doc.data().url,
                leaderboard_url: doc.data().leaderboard_url,
                img_url: doc.data().img_url,
                leaderboard: []
            }
            leaderSC.push(objectSC)
        });
        setLoadGame(false);
        setGameType(leaderSC);
        leaderSC.map((item: GameTypeModel, index: number) => {
            console.log(item.id);
            loadLeaderboardGame(item.id, index);
        })
        console.log(leaderSC);
    }

    const loadLeaderboardGame = async (gameName: string, index: number) => {
        const gameRef = collection(db, gameName);
        const q = query(gameRef, orderBy("score", "desc"), limit(10));
        const leaderSc: any = [];
        const querySnapshot = await getDocs(q);
        let leaderSC: LeaderBoardModel[] = [];
        querySnapshot.forEach((doc) => {
            leaderSc.push(doc.data());
            const objectSC: LeaderBoardModel = {
                isWinner: doc.data().isWinner,
                name: doc.data().name,
                score: doc.data().score,
                timeStamp: doc.data().timeStamp
            }
            leaderSC.push(objectSC)
        });
        setGameType(current =>
            current.map(obj => {

                if (obj.id === gameName) {
                    return { ...obj, leaderboard: leaderSC };
                }

                return obj;

            }),
        );





    }


    useEffect(() => {

        callGame();
    }, [])

    const clickGame = (url, gameName) => {

        console.log(url);
        UtilityService().clickSendEvent('click', 'Landing', 'Click Game ' + gameName);
        window.open(url, '_blank');
    }



    return (
        <>
            <div className='landing'>
                <HeaderComponent></HeaderComponent>
                <div className='container header-control'>
                    <img src={GAME_ROOM_HEADER} className='img-game-room'></img>

                </div>
                {
                    !loadGame ?
                        <div className='container grid-game-control'>

                            {
                                gameType.map((item: GameTypeModel, index: any) => {

                                    return (
                                        <div className='item-control' key={index}>
                                            <div className='logo-game'>
                                                <img src={LOGO_GAME_MOCK} className='logo-img'></img>
                                            </div>
                                            <div className='item-desc-control'>

                                                <span className='header-text'>{item.name}</span>
                                                <span className='desc-text'>{item.desc}</span>
                                                <img src={item.img_url} className="game-img-top"></img>

                                                <div className='button-control'>
                                                    <button className='button-play' onClick={()=>clickGame(item.url, item.name)}>Play</button>
                                                    <span className='button-leaderboard' onClick={()=>clickGame(item.leaderboard_url, item.name)}>Leaderboard</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })


                            }
                        </div>

                        :
                        <div className='loading-control'>
                            <Lottie animationData={LOADING_FILE} loop={true} className='loading-img mx-auto' />
                            <span className='loading-text'>Loading . . . </span>
                        </div>
                }


                {/* <div className='container grid-game-2-control'>
                    <div className='item-control'>
                        <div className='logo-game'>
                            <img src={LOGO_GAME_MOCK} className='logo-img'></img>
                        </div>
                        <div className='item-desc-control'>

                            <span className='header-text'>Gear Crush</span>
                            <span className='desc-text'>Start playing Candy Crush Saga today –
                                a legendary puzzle game loved by millions of
                                players around the world.</span>

                            <div className='button-control'>
                                <button className='button-play'>Play</button>
                                <span className='button-leaderboard'>Leaderboard</span>
                            </div>
                        </div>
                    </div>
                    <div className='item-control'>
                        <div className='logo-game'>
                            <img src={LOGO_GAME_MOCK} className='logo-img'></img>
                        </div>
                        <div className='item-desc-control'>

                            <span className='header-text'>2048</span>
                            <span className='desc-text'>Play Puzzle Game 2048. Use your arrow keys
                                to move the tiles. When two tiles with the
                                same number touch, they merge into one.
                                Try to beat your high score!</span>

                            <div className='button-control'>
                                <button className='button-play'>Play</button>
                                <span className='button-leaderboard'>Leaderboard</span>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='container divider-line'></div>

                <div className='container challenging-control'>

                    {/* <div className='next-challening-control'>
                        <img src={NEXT_IMG} className='img-next'></img>
                    </div> */}

                    <h2 className='challenging-zone-text'>Challenging Zone </h2>
                    {
                        !loadGame ?
                            <Carousel>
                                {
                                    gameType.map((item: GameTypeModel, index: any) => {
                                        return (
                                            <CarouselItem key={index}>
                                                <div className='leader-board-control'>
                                                    <div className='item-control'>
                                                        <div className='logo-game'>
                                                            <img src={LOGO_GAME_MOCK} className='logo-img'></img>
                                                        </div>
                                                        <div className='item-desc-control'>
                                                            <div className='header-text-control'>
                                                                <div className='d-flex flex-column text-control'>
                                                                   
                                                                    <span className='header-text'>{item.name}</span>
                                                                    <span className='desc-text'>{item.desc}</span>
                                                                </div>
                                                                <img src={item.img_url} className="game-img"></img>
                                                            </div>



                                                            <span className='text-leader-board'>Leaderboard</span>

                                                            {
                                                                item.leaderboard != undefined ?

                                                                    item.leaderboard!!.length > 0 ?

                                                                        item.leaderboard!!.map((item: LeaderBoardModel, index: any) => {
                                                                            return (
                                                                                index === 0 ?
                                                                                    <div className='item-rank' key={index}>
                                                                                        <span className='text-rank-1st'>{index + 1}. {item.name}</span>
                                                                                        <span className='text-point-1st'>{item.score.toLocaleString()}</span>
                                                                                    </div> :
                                                                                    index === 1 ?
                                                                                        <div className='item-rank' key={index}>
                                                                                            <span className='text-rank-2nd'>{index + 1}. {item.name}</span>
                                                                                            <span className='text-point-2nd'>{item.score.toLocaleString()}</span>

                                                                                        </div> :
                                                                                        index === 2 ?
                                                                                            <div className='item-rank' key={index}>
                                                                                                <span className='text-rank-3rd'>{index + 1}. {item.name}</span>
                                                                                                <span className='text-point-3rd'>{item.score.toLocaleString()}</span>

                                                                                            </div> :
                                                                                            <div className='item-rank' key={index}>
                                                                                                <span className='text-rank'>{index + 1}. {item.name}</span>
                                                                                                <span className='text-point'>{item.score.toLocaleString()}</span>

                                                                                            </div>

                                                                            )
                                                                        }) :

                                                                        item.leaderboard!!.length === 0 ?
                                                                            <div className='coming-soon-control'>
                                                                                <span className='coming-soon-text my-auto'>Coming Soon . . . </span>
                                                                            </div>
                                                                            :
                                                                            <div className='loading-control'>
                                                                                <Lottie animationData={LOADING_FILE} loop={true} className='loading-img mx-auto' />
                                                                                <span className='loading-text'>Loading . . . </span>
                                                                            </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CarouselItem>

                                        )
                                    })
                                }


                            </Carousel> :
                            <div className='loading-control'>
                                <Lottie animationData={LOADING_FILE} loop={true} className='loading-img mx-auto' />
                                <span className='loading-text'>Loading . . . </span>
                            </div>

                    }


                </div>
                <div className='city-img'></div>
                <FooterComponent></FooterComponent>

            </div>
        </>
    )

}

export default LandingComponent;