import { Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./header.scss";
import MONDAYTOKEN from "../../assets/images/monday-token.png";
import MENU_IMG from "../../assets/images/menu-nav.png";
import BTN_OPENSEA from "../../assets/images/btn-opensea.png";
import BTN_DISCORD from "../../assets/images/btn-discord.png";
import BTN_TWITTER from "../../assets/images/btn-twitter.png";
import CLOSE_IMG from "../../assets/images/close.png";
import config from "../../config";
import React from "react";
import { useNavigate } from "react-router-dom";
import LOGOMONDAY from "../../assets/images/logo-monday.png";
import { useDisclosure } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import UtilityService from "../utility/utility";
const HeaderComponent = () => {
    let navigate = useNavigate();

    const [isOpenNav, setOpen] = React.useState(false);
    const width = useWindowWidth();
    const title = "SALE";
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false

    
    React.useEffect(() => {

    }, [])

    const clickNav = () => {

        if (isOpenNav) {
            UtilityService().clickSendEvent("Click", "Header", "Close Menu ")
            setOpen(false);
        } else {
            UtilityService().clickSendEvent("Click", "Header", "Open Menu ")
            setOpen(true);
        }
    }



    const clickDiscord = () => {
        UtilityService().clickSendEvent("Click", "Header", "Discord ")
        window.open(config.link_social.discord, "_blank")
    }

    const clickTwitter = () => {
        UtilityService().clickSendEvent("Click", "Header", "Twitter ")
        window.open(config.link_social.twitter, "_blank")
    }

    const clickHome = () => {
        UtilityService().clickSendEvent("Click", "Header", "Home ")
        navigate("/", { replace: false });

    }
    const clickBranded = () => {
        UtilityService().clickSendEvent("Click", "Header", "Branded Campaigns")
        window.open("https://mondayclub.io/campaign")
    }
    const clickGameRoom = () => {
        UtilityService().clickSendEvent("Click", "Header", "Game Room")
        navigate("/", { replace: false });
    }
    const clickNFT = () => {
        UtilityService().clickSendEvent("Click", "Header", "SQUAD GALLERY")
        window.open(config.app.nft);
    }
 
    const clickItems = () => {
        UtilityService().clickSendEvent("Click", "Header", "Items")
        navigate("/items", { replace: false });
    }
    const clickPersonalityTest = () => {
        UtilityService().clickSendEvent("Click", "Header", "PERSONALITY TEST")
        window.open(config.app.personality, "_blank");
    }
    const clickStake = () => {
        UtilityService().clickSendEvent("Click", "Header", "Staking")
        window.open(config.app.launch_app, "_blank");
    }
    const clickMonday = () => {
        UtilityService().clickSendEvent("Click", "Header", "Monday Club Landing")
        window.open(config.app.home, "_blank");
    }
    const clickOpensea = () => {
        UtilityService().clickSendEvent("Click", "Header", "Opensea")
        window.open("https://opensea.io/collection/monday-club-nft", "_blank");
    }
  

    return (
        <div className="container header-control">
            <div className="grid-control">
                <div className="d-flex logo-control " onClick={clickHome}>
                    <img src={MONDAYTOKEN} className="logo-monday"></img>
                    <span className="monday-squad">MONDAY SQUAD</span>
                </div>

                <div className="d-flex grid-right-control">
                    <div className="d-none d-md-flex my-auto btn-social-control  ml-auto">
                        <span className="nav-menu-title" onClick={clickMonday}>Home</span>
                        <span className="nav-menu-title" onClick={clickStake}>Invest</span>
                        <span className="nav-menu-title" onClick={clickGameRoom}>Game Room</span>
                        <span className="nav-menu-title" onClick={clickNFT}>NFTs</span>
                        <span className="nav-menu-title" onClick={clickDiscord}>Join Community</span>
                      
                    </div>

                

                    <img src={MENU_IMG} className="d-flex d-md-none menu-img" onClick={clickNav}></img>
                </div>
            </div>
            <div className={!isOpenNav ? "sidenav" : "sidenav-active"}>
                <div className="d-flex flex-column sidenav-control">
                    <img src={CLOSE_IMG} className="close-img" onClick={clickNav}></img>
                    <span className="text-menu" onClick={clickHome}>Home</span>
                    <span className="text-menu" onClick={clickStake}>Staking
                    </span>
                    <span className="text-menu" onClick={clickGameRoom}>Game Room
                    </span>
                    <span className="text-menu " onClick={clickNFT}>NFTs
                    </span>
                    <span className="text-menu " onClick={clickDiscord}>Join Community
                    </span>

                    <div className="d-flex flex-column logo-full-monday-control">
                        <span className="coming-soon">Powered by</span>
                        <img src={LOGOMONDAY} className="logo-full-monday mx-auto" onClick={clickMonday}></img>
                    </div>

                    {/* <button className="d-flex d-md-none connect-wallet-button">
                        
                       <span className="mx-auto my-auto">Connect Wallet
                           </span> 
                        
                        </button> */}
                </div>
            </div>
        </div>
    )

}

export default HeaderComponent;
function NavLinkOutsideLaunchApp({ path, title, option }) {
    return (
        <Button className="d-flex button-launch-app">
            <a href={path} target="_blank"><span>{title}</span></a>
        </Button>

    )
}


function NavLinkOutside({ path, title, option }) {
    return (
        <a href={path} target="_blank" className="nav-link navbar-sm link"><span>{title}</span></a>
    )
}
function NavLink({ path, title }) {
    return (
        <Link to={path} className="nav-link navbar-sm link">{title}</Link>
    )
}

function ButtonConnectWallet({ connectWallet }) {
    return (
        <button className="btn btn-connect" onClick={connectWallet}>Connect wallet</button>
    )
}